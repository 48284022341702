import { makeStyles } from '@material-ui/core/styles'

import { COLORS, FONTS } from 'styles'

const useStyles = makeStyles(theme => ({
  listItem: {
    paddingLeft: 5,
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 16,
  },
  itemIcon: {
    width: 21,
    height: 21,
    margin: '0px 10px',
  },
  itemText: {
    fontFamily: FONTS.POPPINS_SEMI_BOLD,
    fontSize: '0.8rem',
    color: theme.palette.primary.contrastText,
  },
  listItemIcon: {
    minWidth: 0,
  },
}))

export { useStyles }
